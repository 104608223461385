import { of, switchMap, map, tap, iif, defer } from 'rxjs';
import { getPermissions } from 'services/api/proxy-bff-web/bff';
import type { Permissions } from 'services/api/proxy-bff-web/data-contracts';
import is from 'utils/is';

import Store from './store';
import user from './user';

type ModuleAuthorizationStore = Permissions;

class ModuleAuthorization extends Store<ModuleAuthorizationStore | undefined> {
  source$ = user.onChange$.pipe(
    // flags store as loading
    tap(() => {
      super.meta.setLoading(true);
    }),
    switchMap(({ viewType, viewTypeId }) =>
      iif(
        () => viewType === 'studio' || is.nullish(viewTypeId),
        of(this.initialState),
        defer(() =>
          getPermissions({
            headers: {
              'X-Actor-Type': 'performer',
              'X-Actor-Id': viewTypeId,
            },
          }).pipe(map((response) => response.data.data))
        )
      )
    )
  );

  constructor() {
    super({ name: 'module-authorization', initialState: undefined });
  }

  hasScheduledShowsPermission(): boolean {
    return is.array(this.data?.scheduledShows) && this.data?.scheduledShows[0] === 'all';
  }

  hasLoyalfansPermission(): boolean {
    return is.array(this.data?.loyalfans) && this.data?.loyalfans[0] === 'all';
  }

  hasActionRequestsPermission(): boolean {
    return is.array(this.data?.actionRequests) && this.data?.actionRequests[0] === 'all';
  }

  hasBattlePermission(): boolean {
    return is.array(this.data?.battle) && this.data?.battle[0] === 'all';
  }

  hasRoseSendingPermission(): boolean {
    return is.array(this.data?.roseSending) && this.data?.roseSending[0] === 'all';
  }

  hasMemberMessagesPermission(): boolean {
    return is.array(this.data?.messenger) && this.data?.messenger.includes('member');
  }

  hasSupportMessagesPermission(): boolean {
    return is.array(this.data?.messenger) && this.data?.messenger.includes('support');
  }

  hasSystemMessagesPermission(): boolean {
    return is.array(this.data?.messenger) && this.data?.messenger.includes('system');
  }

  hasAutomatedMessagesPermission(): boolean {
    return is.array(this.data?.messenger) && this.data?.messenger.includes('automated-messages');
  }
}

export type { ModuleAuthorizationStore };
export default new ModuleAuthorization();
