import {
  combineLatest,
  of,
  defer,
  map,
  switchMap,
  iif,
  tap,
  retry,
  distinctUntilChanged,
  filter,
  catchError,
  interval,
} from 'rxjs';
import type { Observable, Subscription } from 'rxjs';
import performerCategory from '@dh-msc/performer-category';
import {
  getPerformerPersons,
  innerV2PerformersDetail,
  innerV2PerformersPropertiesDetail,
} from 'services/api/proxy-registration/inner';
import type { PerformerCategory } from 'services/api/proxy-registration/data-contracts';
import { getNewbiePerformerStatus } from 'services/api/proxy/inner';
import { mePerformersPromoPeriodTimeDetail } from 'services/api/account-services/me';
import type { PerformerPromoPeriodTimeDetails } from 'services/api/account-services/data-contracts';
import type { PerformerNewbieStatusResponse } from 'services/api/proxy/data-contracts';
import settings from 'configurations/application';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';
import is from 'utils/is';
import parse from 'utils/parse';
import date, { type DateInput } from 'utils/date';
import type { ObservableType } from 'contracts';
import websocket, { LiveNotificationEvent } from 'services/websocket';
import navigation from 'services/navigation';

import Store from './store';
import user from './user';

type PerformerStatus = Exclude<
  ObservableType<ReturnType<typeof innerV2PerformersDetail>>['data']['data'],
  undefined
>['status'];

interface PerformerStatusUpdatedContent {
  performerId: number;
  statusId: number;
}
type PerformerPromoTime = Exclude<
  ObservableType<ReturnType<typeof mePerformersPromoPeriodTimeDetail>>['data'],
  undefined
>;

type Person = {
  id: number;
  genderId: number;
  fullName: string;
  birthday: DateInput | null;
};

interface PerformerStore {
  id: number;
  displayName: string;
  screenName: string;
  status: PerformerStatus;
  category: PerformerCategory;
  personIds: Array<number>;
  persons: Array<Person>;
  promoTime: PerformerPromoTime;
  flags: {
    messengerReadReceipts: boolean;
    birthdayNotification: boolean;
    exclusiveBadgeStatus: boolean;
    soldierAidCampaignParticipant: boolean;
    isSelected: boolean;
    newbie: boolean;
  };
}

const getTopCategoryWikiLink = (categoryId = -1): string => {
  const page =
    {
      1: 'Categories-nude',
      2: 'Categories-HotFlirt',
      3: 'Categories-celebrity',
      109: 'Categories-Amateur',
    }[categoryId] ?? '';

  return parse.url(settings.envVars.wikiUri, 'Categories', page && `#${page}`);
};

const statusNotNew = (status: PerformerStatus): boolean => status !== 'new';

class Performer extends Store<PerformerStore | undefined> {
  private watchList = [LiveNotificationEvent.PerformerStatusUpdated];

  private websocketSubscription: Subscription | undefined = undefined;

  source$ = user.onChange$.pipe(
    tap(() => super.meta.setLoading(true)),
    switchMap(({ viewTypeId }) =>
      iif(
        () => user.isStudioView() || is.nullish(viewTypeId),
        of(this.initialState).pipe(tap(() => this.closeWebsocket())),
        defer(() =>
          innerV2PerformersDetail(viewTypeId!, {
            headers: {
              'X-Actor-Type': 'performer',
              'X-Actor-Id': viewTypeId!,
            },
          }).pipe(
            map((response) => response.data.data),
            switchMap((details) =>
              combineLatest([
                of(details),
                innerV2PerformersPropertiesDetail(viewTypeId!, {
                  headers: {
                    'X-Actor-Type': 'performer',
                    'X-Actor-Id': viewTypeId!,
                  },
                }).pipe(
                  map((response) => response.data.data.items),
                  retry({ count: 3, delay: 500, resetOnSuccess: true })
                ),
                getPerformerPersons(viewTypeId!, {
                  headers: {
                    'X-Actor-Type': 'performer',
                    'X-Actor-Id': viewTypeId!,
                  },
                }).pipe(
                  map((response) => response.data.data.items),
                  retry({ count: 3, delay: 500, resetOnSuccess: true })
                ),
                statusNotNew(details?.status)
                  ? getNewbiePerformerStatus(Number(viewTypeId).toString(), {
                      headers: {
                        'X-Actor-Type': 'performer',
                        'X-Actor-Id': viewTypeId!,
                      },
                    }).pipe(
                      map((response) => response.data.data),
                      retry({ count: 3, delay: 500, resetOnSuccess: true }),
                      catchError(() =>
                        of({
                          disabledByAdmin: false,
                          isNewbie: false,
                          newbieUntil: '2022-10-03T11:05:27+00:00',
                        } satisfies PerformerNewbieStatusResponse['data'])
                      )
                    )
                  : of(undefined),
                statusNotNew(details?.status)
                  ? mePerformersPromoPeriodTimeDetail(Number(viewTypeId).toString(), {
                      headers: {
                        'X-Actor-Type': 'performer',
                        'X-Actor-Id': viewTypeId!,
                      },
                    }).pipe(
                      map((response) => response.data),
                      retry({ count: 3, delay: 500, resetOnSuccess: true }),
                      catchError(() => of({} satisfies PerformerPromoPeriodTimeDetails))
                    )
                  : of(undefined),
              ])
            )
          )
        ).pipe(
          map(([details, properties, persons, newbie, promoTime]) => {
            return {
              id: viewTypeId!,
              displayName: details?.displayName ?? '',
              screenName: details?.screenName ?? '',
              status: details?.status ?? 'new',
              category: details?.category ?? {},
              personIds: persons?.map((person) => person.id) ?? [],
              persons:
                persons?.map((person) => {
                  return {
                    id: person.id,
                    genderId: person.genderId,
                    fullName: person.fullName,
                    birthday: person.birthDate ? date(person.birthDate).toISODateString() : '',
                  };
                }) ?? [],
              promoTime: promoTime ?? {},
              flags: {
                /* eslint-disable dot-notation, @typescript-eslint/no-unsafe-assignment */
                messengerReadReceipts:
                  properties?.find((prop) => prop.name === 'is_read_receipts_enabled')?.value === true,
                birthdayNotification: properties?.find((prop) => prop.name === 'birthday_visibility')?.value === true,
                exclusiveBadgeStatus:
                  properties?.find((prop) => prop.name === 'exclusive_badge_status')?.value === 'active',
                soldierAidCampaignParticipant:
                  properties?.find((prop) => prop.name === 'soldier_aid_campaign_participant')?.value === true,
                isSelected: properties?.find((prop) => prop.name === 'is_selected')?.value === true,
                /* eslint-enable dot-notation, @typescript-eslint/no-unsafe-assignment */
                newbie: newbie?.isNewbie === true,
              },
            };
          }),
          tap(() => this.subscribeToWebSocket()),
          retry({ count: 3, delay: (err, count) => interval(count * 1000), resetOnSuccess: true }),
          catchError((error) => {
            this.close();

            navigation.internalError();

            throw error;
          })
        )
      )
    )
  );

  constructor() {
    super({
      name: 'performer',
      initialState: undefined,
    });
  }

  private subscribeToWebSocket(): void {
    if (this.websocketSubscription) return;

    this.websocketSubscription = websocket
      .on$<PerformerStatusUpdatedContent>(this.watchList)
      .pipe(filter(({ content }) => content.performerId !== this.data?.id))
      .subscribe(({ content }) => {
        this.set('status', this.statusIdToName(content.statusId));
      });
  }

  private closeWebsocket(): void {
    this.websocketSubscription?.unsubscribe?.();
    this.websocketSubscription = undefined;
  }

  // TODO: this method should exist until BE team fix inconsistency in status type across all system
  private statusIdToName(statusId: number): PerformerStatus {
    return ['new', 'pending', 'rejected', 'active', 'inactive', 'suspended', 'closed'][statusId - 1] as PerformerStatus;
  }

  private getPersonOrDefaultPerson(personId?: number): Person | undefined {
    if (!this.data?.personIds) return undefined;

    if (personId) {
      return this.data.persons?.find((person) => person.id === personId);
    }

    return this.data.persons?.[0];
  }

  public setPerformerFlag(flag: string, value: boolean): void {
    this.set('flags', {
      ...this.data?.flags,
      [flag]: value,
    });
  }

  public get onChange$(): Observable<PerformerStore> {
    return super.onChange$.pipe(filterWhileNullish());
  }

  public get onStatusChange$(): Observable<PerformerStore['status']> {
    return this.onChange$.pipe(
      map(({ status }) => status),
      distinctUntilChanged()
    );
  }

  public get onCategoryChange$(): Observable<number> {
    return this.onChange$.pipe(
      map(({ category }) => category.id!),
      distinctUntilChanged()
    );
  }

  hasNudeCategory(): boolean {
    return performerCategory.is.nude(this.data?.category.id);
  }

  hasNudeAndGirlCategory(): boolean {
    return this.data?.category.id === performerCategory.values.NudeGirl;
  }

  hasNudeAndGayCategory(): boolean {
    return this.data?.category.id === performerCategory.values.GayMale;
  }

  hasNudeAndTransgenderCategory(): boolean {
    return this.data?.category.id === performerCategory.values.NudeTransgender;
  }

  hasNudeAndHermaphroditeCategory(): boolean {
    return this.data?.category.id === performerCategory.values.NudeHermaphrodite;
  }

  hasNudeAndShemaleCategory(): boolean {
    return this.data?.category.id === performerCategory.values.NudeShemale;
  }

  hasHotFlirtCategory(): boolean {
    return performerCategory.is.hotFlirt(this.data?.category.id);
  }

  hasCelebrityCategory(): boolean {
    return performerCategory.is.celebrity(this.data?.category.id);
  }

  hasAmateurCategory(): boolean {
    return performerCategory.is.amateur(this.data?.category.id);
  }

  hasFreeShowCategory(): boolean {
    return performerCategory.is.freeShow(this.data?.category.id);
  }

  hasFreeShowAndGirlCategory(): boolean {
    return this.data?.category.id === performerCategory.values.FreeShowGirl;
  }

  hasMissingDataStatus(): boolean {
    return this.data?.status === 'new';
  }

  hasActiveStatus(): boolean {
    return this.data?.status === 'active';
  }

  hasInactiveStatus(): boolean {
    return this.data?.status === 'inactive';
  }

  hasClosedStatus(): boolean {
    return this.data?.status === 'closed';
  }

  hasPromoPeriod(): boolean {
    return this.data?.promoTime.free?.timeNeeded !== 0 || this.data?.promoTime.private?.timeNeeded !== 0;
  }

  get topCategoryWikiLink(): string {
    return getTopCategoryWikiLink(this.data?.category?.parent?.parent?.id);
  }

  isCouple(): boolean {
    return is.array(this.data?.personIds) && this.data.personIds.length > 1;
  }

  getBirthday(personId?: number): DateInput | null | undefined {
    return this.getPersonOrDefaultPerson(personId)?.birthday;
  }

  getGender(personId?: number): number | undefined {
    return this.getPersonOrDefaultPerson(personId)?.genderId;
  }

  getFullName(personId?: number): string | undefined {
    return this.getPersonOrDefaultPerson(personId)?.fullName;
  }
}

export type { PerformerStore, PerformerStatus };
export default new Performer();
