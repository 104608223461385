import type { Observable, Subscription } from 'rxjs';
import { map, switchMap, iif, of, defer, tap, distinctUntilChanged } from 'rxjs';
import { Store } from 'store';
import user from 'store/user';
import { getPerformerUnreadCounts, getStudioUnreadCounts } from 'services/api/proxy/inner';
import type { PerformerUnreadCountsResponse, StudioUnreadCountsResponse } from 'services/api/proxy/data-contracts';
import websocket, { LiveNotificationEvent } from 'services/websocket';
import is from 'utils/is';

interface UnreadCounterStore {
  news: number;
  messages: {
    support: number;
    member: number;
    system: number;
  };
}

class UnreadCounter extends Store<UnreadCounterStore> {
  private watchList = [LiveNotificationEvent.MessengerMemberMessage];

  private websocketSubscription: Subscription | undefined = undefined;

  source$ = user.onChange$.pipe(
    tap(() => super.meta.setLoading(true)),
    switchMap(({ viewTypeId }) =>
      iif(
        () => is.nullish(viewTypeId),
        of(this.initialState).pipe(tap(() => this.closeWebsocket())),
        defer(() =>
          iif(
            () => user.isStudioView(),
            getStudioUnreadCounts(viewTypeId!, undefined, {
              headers: {
                'X-Actor-Type': 'studio',
                'X-Actor-Id': viewTypeId!,
              },
            }).pipe(tap(() => this.closeWebsocket())),
            getPerformerUnreadCounts(viewTypeId!.toString(), undefined, {
              headers: {
                'X-Actor-Type': 'performer',
                'X-Actor-Id': viewTypeId!,
              },
            }).pipe(tap(() => this.subscribeToWebSocket()))
          )
        ).pipe(
          // TODO: swagger type issue
          // eslint-disable-next-line dot-notation, @typescript-eslint/ban-ts-comment
          map((response) => response.data['data'] as PerformerUnreadCountsResponse & StudioUnreadCountsResponse),
          map((data) => ({
            news: data.news,
            messages: {
              support: data.messages?.support ?? 0,
              member: data.messages?.member ?? 0,
              system: data.messages?.system ?? 0,
            },
          }))
        )
      )
    )
  );

  constructor() {
    super({
      name: 'unread-counter',
      initialState: {
        news: 0,
        messages: {
          support: 0,
          member: 0,
          system: 0,
        },
      },
    });
  }

  private subscribeToWebSocket(): void {
    if (this.websocketSubscription) return;

    this.websocketSubscription = websocket
      .on$(this.watchList)
      .pipe(map(({ event }) => event))
      .subscribe(() => {
        this.set('messages', {
          ...this.data.messages,
          member: this.data.messages.member + 1,
        });
      });
  }

  private closeWebsocket(): void {
    this.websocketSubscription?.unsubscribe?.();
    this.websocketSubscription = undefined;
  }

  get onNewsChange$(): Observable<number> {
    return super.onChange$.pipe(
      map((data) => data.news),
      distinctUntilChanged()
    );
  }

  get onMessagesMemberChange$(): Observable<number> {
    return super.onChange$.pipe(
      map((data) => data.messages.member),
      distinctUntilChanged()
    );
  }

  get onMessagesSupportChange$(): Observable<number> {
    return super.onChange$.pipe(
      map((data) => data.messages.support),
      distinctUntilChanged()
    );
  }

  get onMessagesSystemChange$(): Observable<number> {
    return super.onChange$.pipe(
      map((data) => data.messages.system),
      distinctUntilChanged()
    );
  }
}

export type { UnreadCounterStore };
export default new UnreadCounter();
