import { map, tap, filter, distinctUntilChanged } from 'rxjs';
import type { Observable } from 'rxjs';
import Cache from 'services/cache';
import is from 'utils/is';
import toArray from 'utils/to-array';
import type { DeepRequired } from 'contracts';

import Store from './store';
import account from './account';
import type { AccountStore } from './account';

const cacheService = new Cache('session');
const CACHE_KEY = 'view';

type ViewType = 'model' | 'studio';

type CacheData = Pick<UserStore, 'viewType' | 'viewTypeId'>;

interface UserStore {
  viewType: ViewType;
  viewTypeId: number | undefined;
  userAccountType: AccountStore['type'] | undefined;
  limitedAccess: boolean;
  hasMultiplePerformers: boolean;
  studioStatus: AccountStore['studioStatus'] | undefined;
}

class User extends Store<UserStore> {
  static getCache(): CacheData | undefined {
    try {
      const content = cacheService.get<CacheData>(CACHE_KEY);
      const viewTypes: Array<ViewType> = ['model', 'studio'];

      if (!viewTypes.includes(content.viewType) || !is.number(content.viewTypeId)) {
        return;
      }

      return content;
    } catch {
      return undefined;
    }
  }

  static setCache(data: CacheData): void {
    cacheService.set(CACHE_KEY, data);
  }

  source$ = account.onChange$.pipe(
    distinctUntilChanged(
      (prev, next) =>
        prev?.type === next?.type &&
        prev?.studioId === next?.studioId &&
        prev?.defaultPerformerId === next?.defaultPerformerId &&
        prev?.flags?.isLimitedAccess === next?.flags?.isLimitedAccess &&
        prev?.flags?.hasMultiplePerformers === next?.flags?.hasMultiplePerformers &&
        prev?.studioStatus === next?.studioStatus
    ),
    // flags store as loading
    tap(() => {
      super.meta.setLoading(true);
    }),
    map((accountData) => ({
      viewType: (accountData?.type === 'studio' ? 'studio' : 'model') as ViewType,
      viewTypeId: accountData?.type === 'studio' ? accountData?.studioId : accountData?.defaultPerformerId,
      userAccountType: accountData?.type,
      limitedAccess: accountData?.flags?.isLimitedAccess ?? false,
      hasMultiplePerformers: accountData?.flags?.hasMultiplePerformers ?? false,
      studioStatus: accountData?.studioStatus,
    })),
    map((data) => {
      const cachedData = User.getCache();

      if (!cachedData) {
        return data;
      }

      return {
        ...data,
        viewType: cachedData.viewType,
        viewTypeId: !is.nullish(data.viewTypeId) ? cachedData.viewTypeId : undefined,
      };
    })
  );

  constructor() {
    super({
      name: 'user',
      initialState: {
        viewType: 'model',
        viewTypeId: undefined,
        userAccountType: undefined,
        limitedAccess: false,
        hasMultiplePerformers: false,
        studioStatus: undefined,
      },
    });
  }

  delayStoreUpdateWhen = (): boolean => {
    return is.nullish(this.data.viewTypeId);
  };

  get onChange$(): Observable<UserStore> {
    return super.onChange$.pipe(
      distinctUntilChanged((prev, next) => prev.viewType === next.viewType && prev.viewTypeId === next.viewTypeId)
    );
  }

  get onModelChange$(): Observable<DeepRequired<UserStore>> {
    return this.onChange$.pipe(
      filter(({ viewTypeId }) => this.isModelView() && !is.nullish(viewTypeId)),
      distinctUntilChanged((prev, next) => prev.viewTypeId === next.viewTypeId),
      map((data) => data as DeepRequired<UserStore>)
    );
  }

  get onViewTypeChange$(): Observable<Pick<UserStore, 'viewType' | 'viewTypeId'>> {
    return this.onChange$.pipe(
      map((data) => ({
        viewType: data.viewType,
        viewTypeId: data.viewTypeId,
      })),
      distinctUntilChanged((prev, next) => prev.viewType === next.viewType && prev.viewTypeId === next.viewTypeId)
    );
  }

  get onViewTypeChangeToStudio$(): Observable<Pick<UserStore, 'viewType' | 'viewTypeId'>> {
    return this.onViewTypeChange$.pipe(filter(({ viewType }) => viewType === 'studio'));
  }

  get onViewTypeChangeToModel$(): Observable<Pick<UserStore, 'viewType' | 'viewTypeId'>> {
    return this.onViewTypeChange$.pipe(filter(({ viewType }) => viewType === 'model'));
  }

  hasMultiplePerformers(): boolean {
    return this.data.hasMultiplePerformers;
  }

  isStudioView(): boolean {
    return this.data.viewType === 'studio';
  }

  isModelView(): boolean {
    return this.data.viewType === 'model';
  }

  isSingleAccountType(): boolean {
    return this.data.userAccountType === 'single';
  }

  isModelAccountType(): boolean {
    return this.data.userAccountType === 'model';
  }

  isStudioAccountType(): boolean {
    return this.data.userAccountType === 'studio';
  }

  isStudioActiveStatus(): boolean {
    return this.data.studioStatus === 'active';
  }

  hasLimitedAccess(): boolean {
    return this.data.limitedAccess;
  }

  isLimitedStudioAccountType(): boolean {
    return this.isStudioAccountType() && this.data.limitedAccess;
  }

  /**
   * Flags if studio is impersonating a model
   * @desc Impersonation only happens when account type is "studio" and current model view is "model"
   * Note that a studio could have a model using the same person as the studio owner
   * in that case, it's not impersonation since it's the model owner.
   * @returns { boolean }
   */
  isImpersonating(currentPersonIds: number | Array<number>): boolean {
    if (!this.isStudioAccountType() || this.isStudioView() || !currentPersonIds) return false;

    return !toArray(currentPersonIds).includes(account.data?.studioOwnerUserId as number);
  }

  changeToStudioView(): void {
    const id = account.data?.studioId;

    User.setCache({ viewType: 'studio', viewTypeId: id });

    this.set('viewType', 'studio');
    this.set('viewTypeId', id);

    if (process.env.NODE_ENV === 'production') {
      void navigator?.serviceWorker?.ready?.then((registration) => {
        void registration?.active?.postMessage({ type: 'NAUTILUS_VIEW_TYPE_CHANGE', data: null });
      });
    }
  }

  changeToModelView(performerId: number): void {
    User.setCache({ viewType: 'model', viewTypeId: performerId });

    this.set('viewType', 'model');
    this.set('viewTypeId', performerId);

    if (process.env.NODE_ENV === 'production') {
      void navigator?.serviceWorker?.ready?.then((registration) => {
        void registration?.active?.postMessage({ type: 'NAUTILUS_VIEW_TYPE_CHANGE', data: null });
      });
    }
  }
}

export type { UserStore };
export default new User();
