import type { Observable, Subscription } from 'rxjs';
import { map, switchMap, tap, iif, of, defer, filter, distinctUntilChanged } from 'rxjs';
import { Category, Status, VersionMediaKey } from 'services/api/proxy-performer-profile-picture/data-contracts';
import type { ProfilePictureModel } from 'services/api/proxy-performer-profile-picture/data-contracts';
import { findProfilePictures } from 'services/api/proxy-performer-profile-picture/channel';
import type { ObservableType } from 'contracts';
import is from 'utils/is';
import websocket, { LiveNotificationEvent } from 'services/websocket';

import Store from './store';
import user from './user';

type ProfilePicture = ObservableType<ReturnType<typeof findProfilePictures>>['data']['data'][number];

interface ProfilePictureStatusContent {
  id: ProfilePicture['id'];
}

interface PerformerProfilePicturesStore {
  pictures: Array<ProfilePicture>;
  selectedPicture: ProfilePicture | undefined;
}

class PerformerProfilePictures extends Store<PerformerProfilePicturesStore> {
  private watchList = [LiveNotificationEvent.ProfilePictureAccepted, LiveNotificationEvent.ProfilePictureRejected];

  private websocketSubscription: Subscription | undefined = undefined;

  source$ = user.onChange$.pipe(
    tap(() => super.meta.setLoading(true)),
    switchMap(({ viewTypeId }) =>
      iif(
        () => user.isStudioView() || is.nullish(viewTypeId),
        of(this.initialState).pipe(tap(() => this.closeWebsocket())),
        defer(() =>
          findProfilePictures(undefined, {
            headers: {
              'X-Actor-Type': 'performer',
              'X-Actor-Id': viewTypeId ?? '',
            },
          }).pipe(
            map((response) => response.data.data),
            map((pictures) => ({
              pictures,
              selectedPicture: pictures?.find((picture) => picture.isSelected && picture.category === Category.Glamour),
            })),
            tap(() => this.subscribeToWebSocket())
          )
        )
      )
    )
  );

  constructor() {
    super({
      name: 'performer-profile-pictures',
      initialState: {
        pictures: [],
        selectedPicture: undefined,
      },
    });
  }

  private subscribeToWebSocket(): void {
    if (this.websocketSubscription) return;

    this.websocketSubscription = websocket
      .on$<ProfilePictureStatusContent>(this.watchList)
      .pipe(
        filter(({ content }) => content.id === this.data.selectedPicture?.id && this.isSelectedPictureWaitingApproval())
      )
      .subscribe(({ event }) => {
        if (event === LiveNotificationEvent.ProfilePictureRejected) {
          this.setRejectedStatusForSelectedPicture();

          return;
        }

        if (event === LiveNotificationEvent.ProfilePictureAccepted) {
          this.setAcceptedStatusForSelectedPicture();
        }
      });
  }

  private closeWebsocket(): void {
    this.websocketSubscription?.unsubscribe?.();
    this.websocketSubscription = undefined;
  }

  public get onSelectedPictureApproveStatusChange$(): Observable<boolean> {
    return this.onChange$.pipe(
      map(() => this.isSelectedPictureApproved()),
      distinctUntilChanged()
    );
  }

  public get onAcceptedGlamourProfilePictureStatusChange$(): Observable<boolean> {
    return this.onChange$.pipe(
      map(() => this.hasAcceptedGlamourProfilePicture()),
      distinctUntilChanged()
    );
  }

  hasPictures(): boolean {
    return this.data.pictures.length > 0;
  }

  selectedPictureUrl(): string | undefined {
    const contentUri = this.data.selectedPicture?.versions.find(
      (version) => VersionMediaKey.ProfilePictureCrop43147110Jpg === version.mediaKey
    )?.contentUri;

    return contentUri ?? this.data.selectedPicture?.versions[0].contentUri;
  }

  isSelectedPictureWaitingApproval(): boolean {
    return this.data.selectedPicture?.status === 'waiting' ?? false;
  }

  isSelectedPictureApproved(): boolean {
    return this.data.selectedPicture?.status === 'accepted' ?? false;
  }

  hasAcceptedGlamourProfilePicture(): boolean {
    return Boolean(
      this.data.pictures.find(
        (picture: ProfilePictureModel) => picture.status === Status.Accepted && picture.category === Category.Glamour
      )
    );
  }

  hasSelectedPicturePortraitCrop(): boolean {
    return this.data.selectedPicture?.crops.some((crop) => crop.name === 'portrait') ?? false;
  }

  setAcceptedStatusForSelectedPicture(): void {
    if (!is.nullish(this.data.selectedPicture)) return;

    this.set('selectedPicture', {
      status: 'accepted',
    } as ProfilePicture);

    this.set(
      'pictures',
      this.data.pictures.map((picture) => {
        if (this.data.selectedPicture?.id !== picture.id) {
          return picture;
        }

        return {
          ...picture,
          status: Status.Accepted,
        };
      }) satisfies ProfilePicture[]
    );
  }

  setRejectedStatusForSelectedPicture(): void {
    if (!is.nullish(this.data.selectedPicture)) return;

    this.set('selectedPicture', undefined);
    this.set(
      'pictures',
      this.data.pictures.map((picture) => {
        if (this.data.selectedPicture?.id !== picture.id) {
          return picture;
        }

        return {
          ...picture,
          isSelected: false,
          status: Status.Rejected,
        };
      }) satisfies ProfilePicture[]
    );
  }
}

export type { PerformerProfilePicturesStore };
export default new PerformerProfilePictures();
