import { BehaviorSubject, Subject, of, iif, distinctUntilChanged, switchMap, debounceTime, tap } from 'rxjs';
import type { Observable, Subscription } from 'rxjs';
import deepmerge from 'deepmerge';
import invariant from 'invariant';
import { captureException as SentryCaptureException } from 'services/sentry';
import deepEqual from 'utils/deep-equal';
import shallowCopy from 'utils/shallow-copy';
import is from 'utils/is';

/**
 * @global
 */
type RequestStatus = 'initial' | 'pending' | 'resolved' | 'rejected';

type StoreLifeCycle = 'auto' | 'manual';

/**
 * @global
 * @typedef {object} Logger
 * @property @function log
 * @property @function warn
 * @property @function error
 * @property @function fatal
 */
interface Logger {
  info(message: unknown, origin: string): void;
  warn(message: unknown, origin: string): void;
  error(message: unknown, origin: string): void;
  fatal(message: unknown, origin: string): void;
}

/**
 * @global
 * @typedef {object} MetadataActions
 * @property @function setReady
 * @property @function setLoading
 * @property @function setError
 * @property @function reset
 */
interface MetadataActions {
  setReady(): void;
  setLoading(status: boolean): void;
  setError(error: StoreError): void;
  reset(): void;
}

/**
 * @global
 * @typedef {object} StoreError
 * @property {any} details
 * @property {number} timestamp
 */
interface StoreError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any;
  timestamp: number;
}

/**
 * @global
 * @typedef {object} StoreMetadata
 * @property {boolean} initialized
 * @property {boolean} loading
 * @property {boolean} ready
 * @property {StoreError | undefined} error
 */
interface StoreMetadata {
  readonly status: RequestStatus;
  readonly initialized: boolean;
  readonly loading: boolean;
  readonly ready: boolean;
  readonly error: StoreError | undefined;
}

/**
 * @global
 * @typedef StoreDataType<T>
 * @template T
 */
type StoreDataType<T> = T extends undefined ? T | undefined : T;

/**
 * @global
 * @typedef {object} StoreSettings
 * @property {string} name
 * @property {StoreDataType<T>} initialState
 * @template T
 */
interface StoreSettings<T> {
  name: string;
  initialState: StoreDataType<T>;
  lifeCycle?: StoreLifeCycle;
}

/**
 * Store
 * @description Reactive application state management
 * @abstract
 * @template T
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
abstract class Store<T extends Record<string | number, any> | Array<any> | undefined> {
  /**
   * Data Source (a.k.a. Data Producer).
   * @description This is the data `producer` from your store, where the data will be fed.
   * @protected
   */
  protected readonly source$?: Observable<StoreDataType<T>>;

  /**
   * Close Store on Signal
   * @description Closes store on signal receive.
   * @protected
   */
  protected readonly closeOnSignal$?: Observable<unknown>;

  /**
   * Delay Store Data Update
   * @description Delay store data update when given condition is satisfied.
   * @protected
   * @abstract
   * @readonly
   */
  protected readonly delayStoreUpdateWhen?: () => boolean;

  /**
   * Store Name
   * @description Used for tracing (debug) purpose.
   * @readonly
   */
  public readonly name: string;

  /**
   * RxJs Store Behaviour Subject
   * @description Controls store's data event stream,
   * by holding both its current value and emitting data changes to all subscribers.
   * @private
   */
  private readonly store$: BehaviorSubject<StoreDataType<T>>;

  /**
   * Store Life Cycle
   * @private
   */
  private readonly lifeCycle: StoreLifeCycle = 'auto';

  private snapshot: StoreDataType<T> | undefined;

  /**
   * RxJs Store Metadata Behaviour Subject
   * @description Controls store's Metadata event stream,
   * by holding both its current value and emitting data changes to all subscribers.
   * @private
   */
  private readonly meta$ = new BehaviorSubject<StoreMetadata>({
    status: 'initial',
    initialized: false,
    loading: false,
    ready: false,
    error: undefined,
  });

  /**
   * RxJs Signal Subject
   * @description "signal$" is a binary subject that is used to control the source data flow.
   * If signal is "false", then the initial value is restore; if "true", source$ is (re)fetched.
   * @private
   */
  private readonly signal$ = new Subject<boolean>();

  /**
   * Internal signal$ subscription.
   * @private
   */
  private sourceSubscription: Subscription | undefined;

  /**
   * Internal closeOnSignal$ subscription.
   * @private
   */
  private closeSubscription: Subscription | undefined;

  /**
   * Internal Store Data Queue
   * @description Only used together with "delayStoreUpdateWhen" property.
   * @private
   */
  private queue: StoreDataType<T> | undefined = undefined;

  /**
   * Flags if store is closed
   */
  public closed = true;

  /**
   * Store Initial Data State
   * @property {T}
   * @private
   */
  readonly initialState: StoreDataType<T>;

  /**
   * Store Data
   * @description Access store's current data set.
   * @readonly
   */
  public get data(): StoreDataType<T> {
    return this.store$.value;
  }

  /**
   * Return Store Request Status Metadata
   */
  public get status(): RequestStatus {
    return this.meta$.value.status;
  }

  /**
   * Flags if Store is initialized
   */
  public get initialized(): boolean {
    return this.meta$.value.initialized;
  }

  /**
   * Flags is Store is loading
   */
  public get loading(): boolean {
    return this.meta$.value.loading;
  }

  /**
   * Flags is Store is ready
   */
  public get ready(): boolean {
    return this.meta$.value.ready;
  }

  /**
   * Store Error Data
   * @description If "source$" by any means throws an error, this is the place you will get more details about,
   * otherwise value will be always "undefined".
   */
  public get error(): StoreError | undefined {
    return this.meta$.value.error;
  }

  /**
   * On Store Metadata change.
   * @description Event stream to get notified when Store Metadata changes.
   */
  public get onMetaChange$(): Observable<StoreMetadata> {
    if (this.closed && this.lifeCycle === 'auto') {
      this.init();
    }

    return this.meta$.asObservable().pipe(distinctUntilChanged());
  }

  /**
   * On Data change.
   * @description Event stream to get notified when Store Data changes.
   * @public
   */
  public get onChange$(): Observable<StoreDataType<T>> {
    if (this.closed && this.lifeCycle === 'auto') {
      this.init();
    }

    return this.store$.asObservable().pipe(debounceTime(50));
  }

  /**
   * Event/Message Logger
   * @protected
   */
  protected get log(): Logger {
    return {
      info(): void {
        // TBD
      },
      warn(): void {
        // TBD
      },
      error(message: unknown, origin: string): void {
        SentryCaptureException(message, {
          tags: {
            class: 'Store',
            severity: 'error',
            origin,
          },
        });
      },
      fatal(message: unknown, origin: string): void {
        SentryCaptureException(message, {
          tags: {
            class: 'Store',
            severity: 'fatal',
            origin,
          },
        });
      },
    };
  }

  /**
   * Store Metadata
   * @type {MetadataActions}
   * @protected
   */
  protected get meta(): MetadataActions {
    const { meta$, name, log } = this;

    return {
      setReady() {
        meta$.next({
          status: 'resolved',
          ready: true,
          loading: false,
          initialized: true,
          error: undefined,
        });
      },
      setLoading(status) {
        meta$.next({
          status: 'pending',
          loading: status,
          ready: meta$.value.initialized && !status,
          initialized: meta$.value.initialized,
          error: undefined,
        });
      },
      setError(error) {
        log.error(error.details, name);

        meta$.next({
          status: 'rejected',
          loading: false,
          ready: true,
          initialized: true,
          error,
        });
      },
      reset() {
        meta$.next({ status: 'initial', initialized: false, loading: false, ready: false, error: undefined });
      },
    };
  }

  protected constructor(settings: StoreSettings<T>) {
    invariant(
      is.keyOf(settings, 'initialState'),
      `[Store]: "initialState" was not found. Its expected to have it declared on the constructor.`
    );

    const { name, initialState, lifeCycle = 'auto' } = Object.freeze(settings);

    this.name = name;
    this.store$ = new BehaviorSubject<StoreDataType<T>>(shallowCopy(initialState));
    this.snapshot = undefined;
    this.initialState = shallowCopy(initialState);
    this.lifeCycle = lifeCycle;
  }

  /**
   * Dispatch Store Data
   * @param {T} data
   * @private
   */
  private dispatch(data: StoreDataType<T>): void {
    const next: StoreDataType<T> = this.queue ? deepmerge<StoreDataType<T>>(data, this.queue) : data;

    this.queue = undefined;

    // save as current store state
    this.snapshot = shallowCopy(this.store$.value);

    this.store$.next(next);
  }

  /**
   * Connect Store to Data Source (source$)
   * @private
   */
  private connectToSource(): void {
    if (is.nullish(this.source$)) {
      if (!this.initialized) {
        this.meta.setReady();
      }

      return;
    }

    this.closeSubscription = this.closeOnSignal$?.subscribe(() => {
      this.close();
    });

    this.sourceSubscription = this.signal$
      .pipe(
        // prevents duplications
        distinctUntilChanged(),
        tap(() => this.meta.reset()),
        // signal$ represents a boolean value
        // which turns on and off the store
        // if the signal is positive, then we call the sourceObservable$
        // otherwise, we restore the initialState
        switchMap((signal) => iif(() => signal, this.source$!, of(this.initialState)))
      )
      .subscribe({
        next: (data) => {
          this.dispatch(data);

          if (!this.closed) {
            this.meta.setReady();
          }
        },
        error: (error) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(`[STORE][${this.name}]:`, error);
          }

          this.meta.setError({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            details: error,
            timestamp: new Date().getTime(),
          });
        },
      });
  }

  /**
   * Initialize Store
   * @protected
   */
  protected init(): void {
    this.sourceSubscription?.unsubscribe();
    this.closeSubscription?.unsubscribe();

    this.connectToSource();

    this.closed = false;

    this.signal$.next(true);
  }

  public refresh(): void {
    this.signal$.next(false);
    this.signal$.next(true);
  }

  /**
   * Change Store Data
   * @description Updates store data and emits "onChange$" notification to all subscribers
   * @param {string} key
   * @param {any} value
   */
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  public set(value: T): void;

  // @ts-ignore
  public set<K extends keyof Exclude<T, undefined>>(key: K, value: T[K]): void;

  // @ts-ignore
  public set<K extends keyof Exclude<T, undefined>>(key: K | T, value?: T[K]): void {
    if (is.nullish(value) && (is.nullish(this.data) || is.array(this.data))) {
      const data = key as StoreDataType<T>;

      if (deepEqual(this.store$.value, data)) return;

      const next = data;

      if (this.delayStoreUpdateWhen?.()) {
        this.queue = next;
      } else {
        this.dispatch(next);
      }

      return;
    }

    // @ts-ignore
    if (deepEqual(this.store$.value?.[key], value)) return;

    const next = { ...(this.store$.value || {}), [key as string | number]: value } as StoreDataType<T>;

    if (this.delayStoreUpdateWhen?.()) {
      this.queue = { ...(this.queue || {}), ...next };
    } else {
      this.dispatch(next);
    }
  }
  /* eslint-enable @typescript-eslint/ban-ts-comment */

  /**
   * Reset Store to its initial State
   * @description alias for `close()`
   */
  public reset(): void {
    this.signal$.next(false);
  }

  /**
   * Close Store
   */
  public close(): void {
    this.closed = true;

    this.signal$.next(false);

    this.sourceSubscription?.unsubscribe();
    this.closeSubscription?.unsubscribe();
  }

  public revertLastChange(): void {
    if (is.nullish(this.snapshot)) return;

    this.dispatch(this.snapshot);
    this.snapshot = undefined;
  }
}

export type { StoreError };
export default Store;
